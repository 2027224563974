//import './_components.js';


import JustValidate from 'just-validate';
import Inputmask from "../../node_modules/inputmask/dist/inputmask.es6.js";

export const validateForms = (selector, rules, afterSend) => {
    const form = document?.querySelector(selector);
    const telSelector = form?.querySelector('input[type="tel"]');

    try {
        if (!form) {
            console.log('Нет такого селектора!');
            return false;
        }
    } catch ( e ) {
        console.log(e);
    }

    if (!rules) {
        console.error('Вы не передали правила валидации!');
        return false;
    }
    if (telSelector) {
        const inputMask = new Inputmask('+7 (999) 999-99-99');
        inputMask.mask(telSelector);

        for (let item of rules) {
            if (item.tel) {
                item.rules.push({
                    rule: 'function',
                    validator: function() {
                        const phone = telSelector.inputmask.unmaskedvalue();
                        return phone.length === 10;
                    },
                    errorMessage: item.telError
                });
            }
        }
    }

    const validation = new JustValidate(selector);

    for (let item of rules) {
        validation
            .addField(item.ruleSelector, item.rules);
    }

    // if (checkboxes.length) {
    //     for (let item of checkboxes) {
    //         validation
    //             .addRequiredGroup(
    //                 `${item.selector}`,
    //                 `${item.errorMessage}`
    //             )
    //     }
    // }

    validation.onSuccess((ev) => {
        let formData = new FormData(ev.target);

        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    if (afterSend) {
                        afterSend();
                    }
                    console.log('Отправлено');
                }
            }
        }

        xhr.open('POST', '../wp-content/themes/denisdvigun/mail.php', true);
        xhr.send(formData);

        ev.target.reset();
    })

};

const rules = [
    {
        ruleSelector: '.input-name',
        rules: [
            {
                rule: 'minLength',
                value: 2,
                errorMessage: ' '
            },
            {
                rule: 'required',
                value: true,
                errorMessage: ' '
            }
        ]
    },
    {
        ruleSelector: '.input-email',
        rules: [
            {
                rule: 'email',
                errorMessage: ' '
            },
            {
                rule: 'required',
                value: true,
                errorMessage: ' '
            }
        ]
    },

    {
        ruleSelector: '.input-text',
        rules: [
            {
                rule: 'required',
                value: true,
                errorMessage: ' '
            }
        ]
    },
    {
        ruleSelector: '.input-tel',
        tel: true,
        telError: ' ',
        rules: [
            {
                rule: 'required',
                value: true,
                errorMessage: ' '
            }
        ]
    },
];

const afterForm = () => {
    document.getElementById("messages").innerText = "Ваше сообщение успешно отправлено! Мы с вами свяжемся!"
};

validateForms('#contact_form', rules, afterForm);
